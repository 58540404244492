import React from "react";
import { useEffect, useState } from "react";
import "./cartSidebar.scss";
import { useLocalStorage } from "@uidotdev/usehooks";
import { InputNumber } from "antd";

import { FloatButton } from "antd";
import { Link } from "react-router-dom";
const CartSidebar = () => {
  const [opened, setOpened] = useLocalStorage("cartOpened", false);
  const [cartUmidifiers, setCartUmidifiers] = useLocalStorage(
    "cartUmidifiers",
    0
  );
  const [cartOils, setCartOils] = useLocalStorage("cartOils", []);
  // { type: 1, quantity: 1 }
  const switchOpened = () => {
    setOpened(!opened);
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setOpened(false);
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [opened]);

  return (
    <div className="cart-sidebar">
      <FloatButton
        icon={
          opened ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
            </svg>
          )
        }
        onClick={switchOpened}
        className="cart-button"
      />
      <div className={`sidebar ${opened ? "opened" : ""}`}>
        <div className="top-bar">
          <div className="sidebar-title">
            <svg
              style={{
                width: "24px",
                marginRight: "10px",
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M160 112c0-35.3 28.7-64 64-64s64 28.7 64 64v48H160V112zm-48 48H48c-26.5 0-48 21.5-48 48V416c0 53 43 96 96 96H352c53 0 96-43 96-96V208c0-26.5-21.5-48-48-48H336V112C336 50.1 285.9 0 224 0S112 50.1 112 112v48zm24 48a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm152 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z" />
            </svg>
            Coșul meu
          </div>
          <div className="close-button" onClick={() => setOpened(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
            </svg>
          </div>
        </div>

        <div className="cart-items">
          {cartUmidifiers > 0 && (
            <div className="item">
              <img src="/images/inner_card.jpeg" alt="item" />
              <div className="item-details">
                <div className="title">Norișor umidificator</div>
                <div className="price">
                  {(249.99 * cartUmidifiers).toFixed(2)} RON
                </div>
                <div className="old-price">
                  {(299.99 * cartUmidifiers).toFixed(2)} RON
                </div>
                <div className="quantity">
                  <InputNumber
                    inputMode="numeric"
                    className="quantity_input"
                    controls={{
                      upIcon: <span className="change_quantity">+</span>,
                      downIcon: <span className="change_quantity">—</span>,
                    }}
                    defaultValue={cartUmidifiers}
                    min={1}
                    max={20}
                    size="large"
                    onChange={(value) => {
                      if (value < 1) value = 1;
                      setCartUmidifiers(value);
                    }}
                  />
                  <div
                    className="delete"
                    onClick={() => {
                      setCartUmidifiers(0);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      style={{ width: "15px" }}
                    >
                      <path
                        fill="#ffffff"
                        d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          )}
          {cartOils.length > 0 && (
            <>
              <div className="item">
                <img src="/images/ulei2.webp" alt="item" />
                <div className="item-details">
                  <div className="title">Set 12 Uleiuri esențiale</div>
                  <div className="price">
                    {(49.99 * cartOils.length).toFixed(2)} RON
                  </div>
                  <div className="old-price">
                    {(59.99 * cartOils.length).toFixed(2)} RON
                  </div>
                  <div className="quantity">
                    <InputNumber
                      inputMode="numeric"
                      className="quantity_input"
                      controls={{
                        upIcon: <span className="change_quantity">+</span>,
                        downIcon: <span className="change_quantity">—</span>,
                      }}
                      defaultValue={cartOils.length}
                      min={1}
                      max={20}
                      size="large"
                      onChange={(value) => {
                        if (value < 1) value = 1;
                        setCartOils(
                          Array.from({ length: value }, () => ({
                            type: 1,
                          }))
                        );
                      }}
                    />

                    <div
                      className="delete"
                      onClick={() => {
                        setCartOils([]);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        style={{ width: "15px" }}
                      >
                        <path
                          fill="#ffffff"
                          d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  background: "#ebe9e9",
                  marginTop: "-5px",
                  textAlign: "center",
                  padding: "10px",
                  // grayish color
                  color: "#6d6d6d",
                  fontSize: "12px",
                }}
              >
                Arome: Lavanda, Trandafir, Iasomie, Magnolie, Ocean, Lotus,
                Sandalwood, Osmanthus, Capsuna, Lemon, Lily, Green tea
              </div>
            </>
          )}
        </div>
        {cartUmidifiers < 1 && cartOils.length < 1 && (
          <div className="empty-cart">
            <p>Coșul este gol</p>
            <img
              src="/images/empty_cart.png"
              style={{ width: "100px", margin: "-30px 0 -30px 0 " }}
              alt="empty"
            />
            <div className="buy_button" onClick={() => setOpened(false)}>
              Înapoi la cumpărături
            </div>{" "}
          </div>
        )}
        <div className="total">
          <div className="title">SUB-TOTAL</div>
          <div className="price">
            {(249.99 * cartUmidifiers + 49.99 * cartOils.length).toFixed(2)} RON
          </div>
        </div>
        {cartOils.length < 1 && (
          <div className="similar_items">
            {/* ulei */}
            <div className="cart-items">
              <div className="cart-title">Clienții au mai cumpărat și:</div>
              <div className="item">
                <img src="/images/ulei2.webp" alt="item" />
                <div className="item-details">
                  <div className="title">Uleiuri esențiale</div>
                  <div className="price">49.99 RON</div>
                  <div className="old-price">59.99 RON</div>
                  <div className="quantity">
                    <InputNumber
                      inputMode="numeric"
                      className="quantity_input"
                      controls={{
                        upIcon: <span className="change_quantity">+</span>,
                        downIcon: <span className="change_quantity">—</span>,
                      }}
                      defaultValue={1}
                      min={1}
                      max={20}
                      size="large"
                      style={{
                        opacity: 0,
                      }}
                    />
                    <div
                      className="add"
                      onClick={() => {
                        setCartOils([
                          {
                            type: 1,
                          },
                        ]);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        style={{ width: "15px" }}
                      >
                        <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  background: "#ebe9e9",
                  marginTop: "-5px",
                  textAlign: "center",
                  padding: "10px",
                  color: "#6d6d6d",
                  fontSize: "12px",
                }}
              >
                Arome: Lavanda, Trandafir, Iasomie, Magnolie, Ocean, Lotus,
                Sandalwood, Osmanthus, Capsuna, Lemon, Lily, Green tea
              </div>
            </div>
          </div>
        )}
        {(cartOils.length > 0 || cartUmidifiers > 0) && (
          <Link to="/checkout" onClick={() => setOpened(false)}>
            <div className="buy_button">Finalizează comanda</div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default CartSidebar;
