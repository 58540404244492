import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.scss";
import SectionOne from "./sectionOne/sectionOne";
import SectionThree from "./sectionThree/sectionThree";
import Footer from "./footer/footer";
import CookiesBanner from "./cookies/cookies";
import CartSidebar from "./cartSidebar/cartSidebar";
import CheckoutPage from "./checkout/checkoutPage";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import FinalizeOrder from "./multumim/multumim";

const stripePromise = loadStripe("STRIPE_PUBLISHABLE_API_KEY");

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div className="App">
        <div className="voucher">
          Iubirea e în aer! Ai <span>10% </span>reducere la orice comandă cu
          codul: <span>NORISOR10</span>
        </div>
        <CartSidebar />
        <CookiesBanner />
        <SectionOne />
        <SectionThree />
        <Footer />
      </div>
    ),
  },
  {
    path: "/checkout",
    element: (
      <div className="App">
        <CartSidebar />
        <CookiesBanner />
        <CheckoutPage />
        <Footer />
      </div>
    ),
  },
  {
    path: "/multumim",
    element: (
      <div className="App">
        <CartSidebar />
        <CookiesBanner />
        <FinalizeOrder />
        <Footer />
      </div>
    ),
  },
  // Add more routes as needed
]);

function App() {
  return (
    <React.StrictMode>
      <Elements stripe={stripePromise}>
        <h1
          style={{
            zoom: "0.0001",
            position: "absolute",
            top: "-10000px",
            left: "-10000px",
            opacity: "0",
            display: "none",
          }}
        >
          Un somn mai odihnitor cu Umidificatorul Norisor. Prospețime și
          eleganță chiar in dormitorul tău! Comandă acum
        </h1>
        <RouterProvider router={router} />
      </Elements>
    </React.StrictMode>
  );
}

export default App;
