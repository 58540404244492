import React, { useState } from "react";
import "./sectionThree.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Collapse, Image } from "antd";
import FlipCard from "../sectionOne/flipCard";
import Reveal from "../reveal";
import PriceComponent from "../price";
import BuyWrapper from "../buyWrapper";

const SectionThree = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 768;

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  });

  return (
    <>
      <div className="sectionThree">
        <div className="stars">
          <Reveal direction={"bottom"}>
            <div>
              <img src="images/star.svg" alt="star" />
              <img src="images/star.svg" alt="star" />
            </div>
          </Reveal>
          <div>
            <Reveal direction={"bottom"}>
              <img src="images/star.svg" alt="star" />
              <img src="images/star.svg" alt="star" />
            </Reveal>
          </div>
        </div>
        <div className="text">
          <Reveal direction={"bottom"}>
            Experimentează un <span className="accent">somn odihnitor</span> și
            savurează un <span className="accent">parfum plăcut</span> în
            propria cameră!
          </Reveal>
        </div>
        <Reveal direction={"bottom"}>
          <BuyWrapper>
            <div className="image-grid">
              <div className="wrapper">
                <img src="images/grid_one.jpeg" alt="grid_one" />
              </div>
              <div className="wrapper">
                <img src="images/grid_two.jpeg" alt="grid_two" />
              </div>
              <div className="wrapper">
                <img src="images/grid_three.jpeg" alt="grid_three" />
              </div>
            </div>
          </BuyWrapper>
        </Reveal>
        <Reveal direction={"bottom"}>
          <BuyWrapper>
            <div className="buy_button">comandă acum</div>
          </BuyWrapper>
        </Reveal>
      </div>
      <div className="sectionFour">
        {/* reviews */}
        <div className="reviews">
          <div className="title">
            <span
              style={{
                fontSize: "7.5rem",
                position: "absolute",
                left: "30px",
                top: "90px",
              }}
            >
              <Reveal direction={"bottom"}>"</Reveal>
            </span>
            <Reveal direction={"bottom"}>"Păreri de la clienți</Reveal>
          </div>
          <div className="divider" />
          <div className="carousel">
            <Reveal direction={"bottom"}>
              <img className="cloud one" src="images/cloud1.png" alt="cloud1" />
            </Reveal>
            <img className="cloud two" src="images/cloud2.png" alt="cloud2" />
            {width > breakpoint ? (
              <Carousel
                showArrows={true}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                infiniteLoop={true}
                interval={7500}
                transitionTime={500}
                stopOnHover={false}
                swipeable={false}
                emulateTouch={true}
                autoPlay={true}
              >
                <div className="wrapper">
                  <div className="review">
                    <div className="text">
                      Norișorul este minunat! Mi-a transformat complet
                      experiența de somn, iar aroma plăcută a uleiurilor
                      împrospătează camera.
                    </div>
                    <Image src="images/review1.jpeg" alt="reviewer_two" />
                    <div className="reviewer">
                      <img src="images/erika_a.jpg" alt="reviewer_five" />
                      <div className="name">
                        Erika A. <br />{" "}
                        <span className="accent">Achiziție verificată</span>
                      </div>
                    </div>
                  </div>
                  <div className="review">
                    <div className="text">
                      Dupa jumatate de ora de încărcare, norisorul a mers făra
                      întrerupere toată seara! Recomand uleiul de lavanda.
                    </div>
                    <Image src="images/review2.jpeg" alt="reviewer_one" />
                    <div className="reviewer">
                      <img src="images/vali_e.jpg" />
                      <div className="name">
                        Vali E. <br />{" "}
                        <span className="accent">Achiziție verificată</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="wrapper">
                  <div className="review">
                    <div className="text">
                      Recomand uleiul de trandafir, miroase in toata casa🥰
                    </div>
                    <Image src="images/review3.jpeg" alt="reviewer_three" />
                    <div className="reviewer">
                      <img src="images/maria_b.jpg" alt="reviewer_two" />
                      <div className="name">
                        Maria B. <br />{" "}
                        <span className="accent">Achiziție verificată</span>
                      </div>
                    </div>
                  </div>
                  <div className="review">
                    <div className="text">
                      Este compact, ușor de folosit și se potrivește perfect pe
                      noptieră. Culoarea roșie este foarte frumoasă și creează o
                      atmosferă relaxantă în dormitor.
                    </div>
                    <Image src="images/review5.jpeg" alt="reviewer_five" />
                    {/* <div className="name">- Ciprian M.</div> */}
                    <div className="reviewer">
                      <img src="images/alexia_s.jpg " alt="reviewer_three" />
                      <div className="name">
                        Alexia S. <br />{" "}
                        <span className="accent">Achiziție verificată</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="wrapper">
                  <div className="review">
                    <div className="text">
                      Îmi place că are 7 culori disponibile, iar albastrul este
                      preferatul meu. Este foarte silențios și eficient. O
                      achiziție excelentă pentru oricine caută un umidificator
                      de calitate și cu un design drăguț.
                    </div>
                    {/* <div className="name">- Elena B.</div> */}
                    <div className="reviewer">
                      <img src="images/florin_d.jpg " alt="reviewer_six" />
                      <div className="name">
                        Florin D. <br />{" "}
                        <span className="accent">Achiziție verificată</span>
                      </div>
                    </div>
                  </div>
                  <div className="review">
                    <div className="text">
                      Îmi place! Este atât de drăguț și de relaxant. Sunetul de
                      ploaie este perfect pentru a adormi. Este ușor de utilizat
                      și de curățat. Recomand cu siguranță acest produs!
                    </div>
                    <Image src="images/review4.jpeg" alt="reviewer_four" />
                    {/* <div className="name">- Maria C.</div> */}
                    <div className="reviewer">
                      <img src="images/claudiu_r.jpg " alt="reviewer_four" />
                      <div className="name">
                        Claudiu R. <br />{" "}
                        <span className="accent">Achiziție verificată</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>
            ) : (
              <Carousel
                showArrows={true}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                infiniteLoop={true}
                interval={7500}
                transitionTime={500}
                stopOnHover={false}
                swipeable={false}
                emulateTouch={true}
                autoPlay={true}
              >
                <div className="wrapper">
                  <div className="review">
                    <div className="text">
                      Norișorul este minunat! Mi-a transformat complet
                      experiența de somn, iar aroma plăcută a uleiurilor
                      împrospătează camera.
                    </div>
                    <Image src="images/review1.jpeg" alt="reviewer_two" />
                    <div className="reviewer">
                      <img src="images/erika_a.jpg" alt="reviewer_five" />
                      <div className="name">
                        Erika A. <br />{" "}
                        <span className="accent">Achiziție verificată</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="review">
                    <div className="text">
                      Dupa jumatate de ora de încărcare, norisorul a mers făra
                      întrerupere toată seara! Recomand uleiul de lavanda.
                    </div>
                    <Image src="images/review2.jpeg" alt="reviewer_one" />
                    <div className="reviewer">
                      <img src="images/vali_e.jpg" />
                      <div className="name">
                        Vali E. <br />{" "}
                        <span className="accent">Achiziție verificată</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="wrapper">
                  <div className="review">
                    <div className="text">
                      Recomand uleiul de trandafir, miroase in toata casa🥰
                    </div>
                    <Image src="images/review3.jpeg" alt="reviewer_three" />
                    <div className="reviewer">
                      <img src="images/maria_b.jpg" alt="reviewer_two" />
                      <div className="name">
                        Maria B. <br />{" "}
                        <span className="accent">Achiziție verificată</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="review">
                    <div className="text">
                      Este compact, ușor de folosit și se potrivește perfect pe
                      noptieră. Culoarea roșie este foarte frumoasă și creează o
                      atmosferă relaxantă în dormitor.
                    </div>
                    <Image src="images/review5.jpeg" alt="reviewer_five" />
                    {/* <div className="name">- Ciprian M.</div> */}
                    <div className="reviewer">
                      <img src="images/alexia_s.jpg " alt="reviewer_three" />
                      <div className="name">
                        Alexia S. <br />{" "}
                        <span className="accent">Achiziție verificată</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="wrapper">
                  <div className="review">
                    <div className="text">
                      Îmi place că are 7 culori disponibile, iar albastrul este
                      preferatul meu. Este foarte silențios și eficient. O
                      achiziție excelentă pentru oricine caută un umidificator
                      de calitate și cu un design drăguț.
                    </div>
                    {/* <div className="name">- Elena B.</div> */}
                    <div className="reviewer">
                      <img src="images/florin_d.jpg " alt="reviewer_six" />
                      <div className="name">
                        Florin D. <br />{" "}
                        <span className="accent">Achiziție verificată</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="review">
                    <div className="text">
                      Îmi place! Este atât de drăguț și de relaxant. Sunetul de
                      ploaie este perfect pentru a adormi. Este ușor de utilizat
                      și de curățat. Recomand cu siguranță acest produs!
                    </div>
                    <Image src="images/review4.jpeg" alt="reviewer_four" />
                    {/* <div className="name">- Maria C.</div> */}
                    <div className="reviewer">
                      <img src="images/claudiu_r.jpg " alt="reviewer_four" />
                      <div className="name">
                        Claudiu R. <br />{" "}
                        <span className="accent">Achiziție verificată</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>
            )}
          </div>
        </div>
      </div>
      <div className="QA">
        <Reveal direction={"bottom"}>
          <div className="title">Întrebări frecvente</div>
        </Reveal>
        <Collapse accordion defaultActiveKey={["1"]}>
          <Collapse.Panel
            header="01. Ce include Norișorul Umidificator?"
            key="1"
          >
            <p>
              - umidificatorul norisor <br />- 1 cablu USB pentru încărcare
            </p>
          </Collapse.Panel>
          <Collapse.Panel header="02. Cum funcționează?" key="2">
            <p>
              Vasul norișorului trebuie umplut cu apă și conectat la cablul USB
              care este inclus în pachet. Tot ce mai trebuie este să-l pornești
              și să te bucuri de ambientul relaxant.
            </p>
          </Collapse.Panel>
          <Collapse.Panel header="03. În cat timp ajunge comanda?" key="3">
            <p>
              Comenzile sunt expediate în fiecare zi lucrătoare. Livrarea ajunge
              de obicei în 24-48h.
            </p>
          </Collapse.Panel>
          <Collapse.Panel
            header="04. Ce metode de plată avem disponibile?"
            key="4"
          >
            <p>
              - plata cash la livrare <br />- plata cu cardul online
            </p>
          </Collapse.Panel>
          <Collapse.Panel header="05. Mai ai și alte întrebări?" key="5">
            <p>
              Ne poți contacta la
              <a href="mailto:contact@norinorisorumidificator.ro">
                contact@norinorisorumidificator.ro
              </a>
              .
            </p>
          </Collapse.Panel>
        </Collapse>
      </div>
      {/* <Reveal direction={"bottom"}>
        <div className="video_wrapper">
          <div className="video">
            <iframe
              src="https://player.vimeo.com/video/908414765?badge=0&amp;autopause=0&amp;autoplay=1&amp;loop=1&amp;player_id=0&amp;controls=0&amp;app_id=58479"
              frameBorder="0"
              sidedock="0"
              title="0"
              allow="autoplay; fullscreen; picture-in-picture"
              width={width > breakpoint ? "800" : "400"}
              height={width > breakpoint ? "450" : "225"}
              style={{
                borderRadius: 20,
              }}
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </Reveal> */}

      <div className="overwrite">
        <div
          className="sectionTwo"
          style={{
            marginBottom: 100,
          }}
        >
          <div className="about_buy">
            <Reveal direction={"bottom"}>
              <div className="title"> Set 12 Uleiuri esențiale </div>
            </Reveal>
            <div
              style={{
                position: "relative",
              }}
              className="about_inner"
            >
              <PriceComponent price={49.99} />
              <Reveal direction={"bottom"}>
                <div className="about_title">
                  SET 12 ULEIURI <br /> ESENTIALE
                </div>
              </Reveal>
              <BuyWrapper oil={true}>
                <FlipCard source="images/ulei2.webp" />
              </BuyWrapper>
              <Reveal direction={"bottom"}>
                <div className="about_title">PENTRU AROMATERAPIE</div>
              </Reveal>
            </div>
            <Reveal direction={"bottom"}>
              <BuyWrapper oil={true}>
                <div className="buy_button">comandă acum</div>
              </BuyWrapper>
            </Reveal>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionThree;
