import React from "react";
import { useEffect } from "react";
import { Form, Input, Button } from "antd";

import { Link } from "react-router-dom";
import "./cart.scss";
//helmet
import { Helmet } from "react-helmet";

//stripe
import { useStripe, useElements } from "@stripe/react-stripe-js";
//axios
import axios from "axios";

//fatrash
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";

//local storage
import { useLocalStorage } from "@uidotdev/usehooks";

import { Select, notification, Checkbox, Radio } from "antd";
const data = [];
const judete = [
  { abr: "ab", nume: "Alba" },
  { abr: "ar", nume: "Arad" },
  { abr: "ag", nume: "Argeș" },
  { abr: "bc", nume: "Bacău" },
  { abr: "bh", nume: "Bihor" },
  { abr: "bn", nume: "Bistrița-Năsăud" },
  { abr: "bt", nume: "Botoșani" },
  { abr: "br", nume: "Brăila" },
  { abr: "bv", nume: "Brașov" },
  { abr: "b", nume: "București" },
  { abr: "bz", nume: "Buzău" },
  { abr: "cl", nume: "Călărași" },
  { abr: "cs", nume: "Caraș-Severin" },
  { abr: "cj", nume: "Cluj" },
  { abr: "ct", nume: "Constanța" },
  { abr: "cv", nume: "Covasna" },
  { abr: "db", nume: "Dâmbovița" },
  { abr: "dj", nume: "Dolj" },
  { abr: "gl", nume: "Galați" },
  { abr: "gr", nume: "Giurgiu" },
  { abr: "gj", nume: "Gorj" },
  { abr: "hg", nume: "Harghita" },
  { abr: "hr", nume: "Harghita" },
  { abr: "hd", nume: "Hunedoara" },
  { abr: "il", nume: "Ialomița" },
  { abr: "is", nume: "Iași" },
  { abr: "if", nume: "Ilfov" },
  { abr: "mm", nume: "Maramureș" },
  { abr: "mh", nume: "Mehedinți" },
  { abr: "ms", nume: "Mureș" },
  { abr: "nt", nume: "Neamț" },
  { abr: "ot", nume: "Olt" },
  { abr: "ph", nume: "Prahova" },
  { abr: "sj", nume: "Sălaj" },
  { abr: "sm", nume: "Satu Mare" },
  { abr: "sb", nume: "Sibiu" },
  { abr: "sv", nume: "Suceava" },
  { abr: "tr", nume: "Teleorman" },
  { abr: "tm", nume: "Timiș" },
  { abr: "tl", nume: "Tulcea" },
  { abr: "vl", nume: "Vâlcea" },
  { abr: "vs", nume: "Vaslui" },
  { abr: "vn", nume: "Vrancea" },
];

const voucher_str = "NORISOR10";

const CheckoutPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [stripeLink, setStripeLink] = React.useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const [cartUmidifiers, setCartUmidifiers] = useLocalStorage(
    "cartUmidifiers",
    0
  );
  const [cartOils, setCartOils] = useLocalStorage("cartOils", []);

  const [cash, setCash] = React.useState(false);
  const [packaged, setPackaged] = React.useState(false);

  const [total, setTotal] = React.useState(
    cartUmidifiers * 249.99 + cartOils.length * 49.99
  );

  useEffect(() => {
    setTotal(cartUmidifiers * 249.99 + cartOils.length * 49.99);
  }, [cartUmidifiers, cartOils]);

  const openNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  const handleOpenStripeLink = async () => {
    localStorage.removeItem("cartUmidifiers");
    localStorage.removeItem("cartOils");
    setLoading(true);
    setStripeLink(null);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    if (cash) {
      axios
        .post(process.env.REACT_APP_API_URL + "/orders/addCashOrder", {
          paymentIntentId: "",
          name: values.name,
          surname: values.surname,
          phone: values.phone,
          email: values.email,
          county: values.judet,
          city: values.oras,
          address: values.adresa,
          postalCode: values.codPostal,
          cash: cash,
          packaged: packaged,
          umidifiersOrdered: cartUmidifiers,
          oilsOrdered: cartOils.length,
          voucher: voucher,
        })
        .then((res) => {
          setLoading(false);
          localStorage.removeItem("cartUmidifiers");
          localStorage.removeItem("cartOils");
          window.location.href = "/multumim";
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          openNotification(
            "error",
            "Eroare la finalizarea comenzii!",
            "Te rugam sa incerci din nou."
          );
        });
    } else {
      var paymentLink = "";
      axios
        .post(process.env.REACT_APP_API_URL + "/orders/getPaymentLink", {
          name: values.name,
          surname: values.surname,
          phone: values.phone,
          email: values.email,
          county: values.judet,
          city: values.oras,
          address: values.adresa,
          postalCode: values.codPostal,
          cash: cash,
          packaged: packaged,
          umidifiersOrdered: cartUmidifiers,
          oilsOrdered: cartOils.length,
          voucher: voucher,
        })
        .then((res) => {
          paymentLink = res.data.url;
          setStripeLink(paymentLink);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          openNotification(
            "error",
            "Eroare la finalizarea comenzii!",
            "Te rugam sa incerci din nou."
          );
        });
    }
  };

  const [voucherNeaplicat, setVoucherNeaplicat] = React.useState("");
  const [voucher, setVoucher] = React.useState("");
  const onFinish = (values) => {
    console.log("Shipping data:", values);
    // Add logic to handle the shipping data
  };

  return (
    <div className="cart">
      <Helmet
        title="Norisorul Umidificator - Checkout"
        description="Norisorul Umidificator - Checkout"
        keywords="Norisorul Umidificator - Checkout"
        meta={[
          {
            name: "description",
            content: "Norisorul Umidificator - Checkout",
          },
        ]}
      />
      <div className="top_bar">
        <Link to="/">
          <img className="logo" src="/images/logo.png" alt="logo" />
        </Link>
      </div>
      <div className="container">
        <div className="cart-items">
          {cartUmidifiers > 0 || cartOils.length > 0 ? (
            <>
              {cartUmidifiers > 0 && (
                <div className="cart-item" key={1}>
                  <div className="cart-item-image">
                    <img src="/images/inner_card.jpeg" alt="item" />
                  </div>
                  <div className="cart-item-details">
                    <div className="cart-item-title">
                      Norisorul Umidificator x{cartUmidifiers}
                    </div>
                    <div className="cart-item-price">
                      {(cartUmidifiers * 249.99).toFixed(2)} lei
                    </div>
                    <div className="cart-item-remove">
                      <button
                        onClick={() => {
                          setCartUmidifiers(0);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {cartOils.length > 0 && (
                <div className="cart-item">
                  <div className="cart-item-image">
                    <img src="/images/ulei2.webp" alt="item" />
                  </div>
                  <div className="cart-item-details">
                    <div className="cart-item-title">
                      Set 12 Uleiuri esențiale x{cartOils.length}
                    </div>
                    <div className="cart-item-price">
                      {(cartOils.length * 49.99).toFixed(2)} lei
                    </div>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        fontSize: 12,
                        color: "rgba(0, 0, 0, 0.6)",
                        maxWidth: "200px",
                      }}
                    >
                      Arome: Lavanda, Trandafir, Iasomie, Magnolie, Ocean,
                      Lotus, Sandalwood, Osmanthus, Capsuna, Lemon, Lily, Green
                      tea
                    </div>
                    <div className="cart-item-remove">
                      <button
                        onClick={() => {
                          setCartOils([]);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <Input
                  size="large"
                  // style={{
                  //   width: 300,
                  // }}
                  className="voucherInput"
                  placeholder="Voucher"
                  onChange={(e) => setVoucherNeaplicat(e.target.value)}
                />
                <button
                  className="voucherButton"
                  onClick={() => {
                    if (voucherNeaplicat.toUpperCase() === voucher_str) {
                      setVoucher(voucher_str);
                      openNotification(
                        "success",
                        "Voucher aplicat cu succes!",
                        "Discount de 10% a fost aplicat."
                      );
                    } else {
                      openNotification(
                        "error",
                        "Voucher incorect!",
                        "Voucher-ul nu este valid."
                      );
                    }
                  }}
                >
                  Aplica voucher
                </button>
              </div>
            </>
          ) : (
            <div className="empty-cart">
              <div className="empty-cart-title">Coșul tău este gol.</div>
              <div className="empty-cart-text">
                Ai putea să adaugi câteva produse în coș sau să te întorci la
                magazin.
              </div>
              <a href="/">
                <button className="empty-cart-button">Înapoi la magazin</button>
              </a>
            </div>
          )}
        </div>
        <div className="checkout-info">
          <div
            className="row"
            style={{
              marginLeft: "40%",
            }}
          >
            Total Coș
          </div>
          <div className="row">
            <div className="left">Sub-total </div>
            <div className="right">{total.toFixed(2)} lei</div>
          </div>
          {packaged ? (
            <div className="row">
              <div className="left">Transport + Împachetare cadou</div>
              <div className="right">25.99 lei</div>
            </div>
          ) : (
            <div className="row">
              <div className="left">Transport</div>
              <div className="right">15.99 lei</div>
            </div>
          )}
          {voucher == voucher_str && (
            <div className="row">
              <div className="left">Discount</div>
              <div className="right">
                -{(Math.floor(total * 0.1 * 100) / 100).toFixed(2)} lei
              </div>
            </div>
          )}
          <div
            className="line"
            style={{
              width: "100%",
              marginBottom: "10px",
              border: "1px solid #e0e0e0",
            }}
          ></div>
          <div className="row">
            <div className="left">Total</div>
            <div className="right">
              {(
                (voucher == voucher_str
                  ? total * 0.9 + (packaged ? 25.99 : 15.99)
                  : total + (packaged ? 25.99 : 15.99)) +
                (voucher == voucher_str ? 0.01 : 0)
              ).toFixed(2)}{" "}
              lei
            </div>
          </div>
          <div
            style={{
              marginBottom: 20,
            }}
          >
            <Checkbox onChange={(e) => setPackaged(e.target.checked)}>
              Împachetare cadou (+ 9.99 lei)
            </Checkbox>
          </div>
          <br />
          <Form.Item
            // label="Metoda de plata"
            label={
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Metoda de plată:
              </div>
            }
            labelCol={{ span: 24 }}
          >
            {/* <Select
              placeholder="Plata"
              defaultValue={cash}
              onChange={(e) => setCash(e)}
            >
              <Select.Option value={true}>Cash, la livrare</Select.Option>
              <Select.Option value={false}>Card</Select.Option>
            </Select> */}
            <Radio.Group
              disabled={
                loading ||
                stripeLink != null ||
                (cartUmidifiers < 1 && cartOils.length < 1)
              }
              onChange={(e) => setCash(e.target.value)}
              value={cash}
            >
              <Radio value={true}>Cash, la livrare</Radio>
              <Radio value={false}>Card</Radio>
            </Radio.Group>
            <div>Plata cu cardul se face în doi pași.</div>
          </Form.Item>
          <h2>Date de livrare</h2>
          <Form
            disabled={
              loading ||
              stripeLink != null ||
              (cartUmidifiers < 1 && cartOils.length < 1)
            }
            onFinish={handleFormSubmit}
            size="large"
            name="buyer-info"
            className="buyer-info"
          >
            <Form.Item
              style={{
                width: "45%",
              }}
              name="name"
              label="Nume"
              rules={[
                {
                  required: true,
                  message: "Introduceti numele!",
                },
              ]}
            >
              <Input placeholder="Nume" />
            </Form.Item>
            <Form.Item
              style={{
                width: "45%",
                marginLeft: "10%",
              }}
              label="Prenume"
              name="surname"
              rules={[
                {
                  required: true,
                  message: "Introduceti prenumele!",
                },
              ]}
            >
              <Input placeholder="Prenume" />
            </Form.Item>
            <Form.Item
              style={{
                width: "45%",
              }}
              name="phone"
              label="Telefon"
              rules={[
                {
                  required: true,
                  message: "Introduceti numarul de telefon!",
                },
              ]}
            >
              <Input placeholder="Telefon" />
            </Form.Item>
            <Form.Item
              style={{
                width: "45%",
                marginLeft: "10%",
              }}
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Introduceti adresa de email!",
                },
                {
                  type: "email",
                  message: "Introduceti o adresa de email valida!",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              style={{
                width: "45%",
              }}
              name="judet"
              label="Judet"
              rules={[
                {
                  required: true,
                  message: "Introduceti judetul!",
                },
              ]}
            >
              <Select placeholder="Judet">
                {judete.map((judet) => (
                  <Select.Option value={judet.nume}>{judet.nume}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{
                width: "45%",
                marginLeft: "10%",
              }}
              label="Oras"
              name="oras"
              rules={[
                {
                  required: true,
                  message: "Introduceti orasul!",
                },
              ]}
            >
              <Input placeholder="Oras" />
            </Form.Item>
            <Form.Item
              style={{
                width: "100%",
              }}
              name="adresa"
              label="Adresa"
              rules={[
                {
                  required: true,
                  message: "Introduceti adresa!",
                },
              ]}
            >
              <Input placeholder="Adresa" />
            </Form.Item>
            <Form.Item
              style={{
                width: "45%",
              }}
              name="codPostal"
              label="Cod Postal"
              rules={[
                {
                  required: true,
                  message: "Introduceti codul postal!",
                },
              ]}
            >
              <Input placeholder="Cod Postal" />
            </Form.Item>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: 40,
              }}
            >
              {cash && !loading ? (
                <button
                  disabled={
                    loading || (cartUmidifiers < 1 && cartOils.length < 1)
                  }
                >
                  Plasează comanda
                </button>
              ) : (
                <>
                  {stripeLink == null && !loading && (
                    <button
                      disabled={
                        loading || (cartUmidifiers < 1 && cartOils.length < 1)
                      }
                    >
                      Confirmă datele
                    </button>
                  )}
                </>
              )}
            </div>
          </Form>
          {stripeLink && !loading && !cash && (
            <>
              <div className="stripe-info">
                Datele dvs. au fost salvate, vă rugăm continuați spre plată.
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <a href={stripeLink} target="_blank">
                  <button onClick={handleOpenStripeLink} disabled={loading}>
                    Continuă la plata
                  </button>
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
