import React from "react";
import { useSpring, animated } from "react-spring";
import { Badge } from "antd";
import BuyWrapper from "../buyWrapper";
const FlipCard = ({ source, oil }) => {
  const [hovered, setHovered] = React.useState(false);
  const cardAnimation = useSpring({
    transform: `rotateY(${hovered ? 360 : 0}deg) scale(${hovered ? 1.2 : 1})`,
    config: { mass: 3, tension: 100, friction: 20, precision: 0.00001 },
  });

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <BuyWrapper oil={oil}>
        <animated.div
          className="flip-card"
          style={{
            ...cardAnimation,
            background: `url('${source}')`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundOrigin: "content-box",
            padding: "20px",
            width: "60%",
            aspectRatio: "3/4",
            cursor: "pointer",
          }}
        ></animated.div>
      </BuyWrapper>
    </div>
  );
};

export default FlipCard;
