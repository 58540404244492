import React, { useRef } from "react";
import { useSpring, animated } from "react-spring";
import "./homeCard.scss";
import Reveal from "../reveal";
import { Badge } from "antd";
import BuyWrapper from "../buyWrapper";

const HomeCard = () => {
  //make a wrapper with an image in the center, which moves towards the mouse up to 100px
  //on mouse move, move the image towards the mouse
  //on mouse leave, move the image back to the center

  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    rotation: [0, 0, 0],
    config: { mass: 10, tension: 200, friction: 20, precision: 0.00001 },
  }));

  const handleMouseMove = (e) => {
    const rect = cardRef.current.getBoundingClientRect();
    const xPos = e.clientX - rect.left;
    const yPos = e.clientY - rect.top;

    //maximum range is 200px
    const xRange = 150;
    const yRange = 150;

    const xPercentage = xPos / rect.width;
    const yPercentage = yPos / rect.height;

    const x = xPercentage * xRange - xRange / 2;
    const y = yPercentage * yRange - yRange / 2;

    const scale = 1.1;

    set({
      xys: [x, y, scale],
      rotation: [(xPercentage * 90 - 45) * 0.3, 0],
    });
  };

  const handleMouseLeave = (e) => {
    set({ xys: [0, 0, 1] });
    set({ rotation: [0, 0, 0] });
  };

  const cardRef = useRef(null);

  return (
    <div
      className="home-card"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      ref={cardRef}
    >
      <div className="under-card">
        <Reveal direction={"bottom"}>
          <div className="title">un somn mai bun</div>
        </Reveal>
        <Reveal direction={"bottom"}>
          <BuyWrapper>
            <div className="button">comandă acum</div>
          </BuyWrapper>
        </Reveal>
      </div>
      <BuyWrapper>
        <animated.div
          className="card-image"
          style={{
            marginTop: "-100px",
            width: "300px",
            aspectRatio: "3/4",
            transform: props.xys.interpolate(
              (x, y, s) => `translate3d(${x}px, ${y}px, 0px) scale(${s}) `
            ),
            rotate: props.rotation.interpolate((x) => `${x}deg`),
            background: "url(/images/main-card.png",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            borderRadius: "30px",
            boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.5)",
            border: "1px solid rgba(0,0,0,0.2)",
          }}
        ></animated.div>
      </BuyWrapper>
    </div>
  );
};

export default HomeCard;
