import React from "react";
import "./sectionOne.scss";
import HomeCard from "./homeCard";
import FlipCard from "./flipCard";
import Reveal from "../reveal";
import { Badge } from "antd";
import PriceComponent from "../price";
import BuyWrapper from "../buyWrapper";
import { Link } from "react-router-dom";
const SectionOne = () => {
  return (
    <>
      {/* <Reveal direction={"top"}> */}
      {/* </Reveal> */}
      <div className="sectionOne">
        <div className="top_bar">
          <Link to="/">
            <img className="logo" src="/images/logo.png" alt="logo" />
          </Link>
          <BuyWrapper>
            <div className="button">comandă acum</div>
          </BuyWrapper>
        </div>
        <img
          className="background_logo"
          src="/images/logo.png"
          alt="background logo"
          style={{ opacity: 0.7, width: "800px" }}
        />
        <Reveal direction={"left"}>
          <div className="bubble left">
            <img src="/images/bubbles_1.png" alt="bubbles" />
          </div>
        </Reveal>
        <Reveal direction={"right"}>
          <div className="bubble right">
            <img src="/images/bubbles_2.png" alt="bubbles" />
          </div>
        </Reveal>
        <HomeCard />
      </div>
      <div className="sectionTwo">
        <div className="transition">
          <img src="/images/white curve.svg" alt="white curve" />
        </div>
        <div className="about_buy">
          <Reveal direction={"bottom"}>
            <div className="title"> NORIȘORUL UMIDIFICATOR</div>
          </Reveal>
          <div className="about_inner" style={{ position: "relative" }}>
            <PriceComponent price={249.99} />
            <Reveal direction={"bottom"}>
              <div className="about_title">Un somn mai bun?</div>
            </Reveal>
            <FlipCard oil={false} source="images/inner_card.jpeg" />

            <Reveal direction={"bottom"}>
              <div className="about_title">avem soluția</div>
            </Reveal>
          </div>
          <Reveal direction={"bottom"}>
            <BuyWrapper>
              <div className="buy_button">comandă acum</div>
            </BuyWrapper>
          </Reveal>
        </div>
      </div>
    </>
  );
};

export default SectionOne;
