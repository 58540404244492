import React, { useState } from "react";
import "./cookies.scss";

const CookiesBanner = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(
    localStorage.getItem("acceptedCookies")
  );

  const handleAcceptCookies = () => {
    localStorage.setItem("acceptedCookies", true);
    setAcceptedCookies(true);
  };

  if (acceptedCookies) {
    return null; // Cookies banner is hidden if cookies are already accepted
  }

  return (
    <div className="cookies-banner">
      <p>Acest site folosește cookies pentru a vă îmbunătăți experiența.</p>
      <p>Prin continuarea navigării, sunteți de acord cu utilizarea lor.</p>
      <button onClick={handleAcceptCookies}>OK</button>
    </div>
  );
};

export default CookiesBanner;
