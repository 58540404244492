import React from "react";
import { Link } from "react-router-dom";
import "./multumim.scss";

const FinalizeOrder = () => {
  return (
    <div className="ty">
      <div className="top_bar">
        <Link to="/">
          <img className="logo" src="/images/logo.png" alt="logo" />
        </Link>
      </div>
      <div
        className="container_ty"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          backgroundColor: "#f5f5f5",
        }}
      >
        <h1
          style={{
            fontSize: "2rem",
            fontWeight: "bold",
          }}
        >
          Vă mulțumim pentru comandă!
        </h1>
        <p
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          Comanda dvs. a fost plasată. Veți primi un e-mail de confirmare în cel
          mai scurt timp.
          <br />
          Vă rugăm să verificați și folderul de spam.
        </p>
        <a href="/">
          <div className="buy_button">Înapoi la magazin</div>
        </a>
      </div>
    </div>
  );
};

export default FinalizeOrder;
