import React from "react";
import { useLocalStorage } from "@uidotdev/usehooks";

const BuyWrapper = ({ oil, children }) => {
  const [cartOils, setCartOils] = useLocalStorage("cartOils", []);
  const [cartUmidifiers, setCartUmidifiers] = useLocalStorage(
    "cartUmidifiers",
    0
  );
  const [opened, setOpened] = useLocalStorage("cartOpened", false);

  const handleBuy = () => {
    console.log("buying");
    if (cartUmidifiers < 1) {
      setCartUmidifiers(1);
    }

    if (oil) {
      if (cartOils.length < 1) {
        setCartOils([
          {
            type: 1,
            quantity: 1,
          },
        ]);
      }
    }
    setOpened(true);
  };

  // Wrap each child with the buy functionality
  const childrenWithBuyProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      onClick: () => {
        handleBuy();
        if (child.props.onClick) {
          child.props.onClick();
        }
      },
    })
  );

  return <>{childrenWithBuyProps}</>;
};

export default BuyWrapper;
