import React from "react";
import "./footer.scss";
import { Input, Button, Space, notification } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const Footer = () => {
  const [api, contextHolder] = notification.useNotification();

  const handleSubmit = (email) => {};

  return (
    <footer className="footer">
      {contextHolder}
      <div className="left">
        <Link to="/">
          <img src="images/logo.png" alt="logo" />
        </Link>
        <div className="socials">
          <a
            href="https://www.tiktok.com/@norisorumidificator?_t=8jHIlvQu9hI&_r=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                fill="#ffffff"
                d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"
              />
            </svg>
          </a>
          <a
            href="https://www.instagram.com/norisorumidificator/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                fill="#ffffff"
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              />
            </svg>
          </a>
          <a
            href="https://www.facebook.com/norisorumidificator?mibextid=eQY6cl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                fill="#ffffff"
                d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="middle">
        <div className="newsletter-bar">
          <div className="title">Abonează-te la newsletter</div>
          <Space align="center">
            <MailOutlined style={{ fontSize: "20px", color: "white" }} />
            <Input
              placeholder="Introduceți adresa de email..."
              className="custom-input"
            />
            <Button
              type="primary"
              className="custom-button"
              onClick={() => {
                const email = document.querySelector(".custom-input").value;
                if (email === "") {
                  api.info({
                    message: "Eroare",
                    description: "Introduceți o adresă de email validă.",
                    placement: "bottomRight",
                  });
                  return;
                }
                api.success({
                  message: "Succes",
                  description: "Vă mulțumim pentru abonare!",
                  placement: "bottomRight",
                });
                handleSubmit(email);
              }}
            >
              Abonare
            </Button>
          </Space>
        </div>
      </div>

      <div className="right">
        <div className="item">
          <a alt="anpc" href="https://anpc.ro">
            ANPC
          </a>{" "}
          <a alt="GPDR" href="https://www.dataprotection.ro">
            GPDR
          </a>
        </div>
        {/* <div className="item">CUMPARA ACUM</div> */}
        <div className="item">Contactează-ne la</div>
        <div className="item">
          <a href="mailto:contact@norinorisorumidificator.ro">
            contact@norinorisorumidificator.ro
          </a>
        </div>
        <div className="item">
          © {new Date().getFullYear()} Norisor Umidificator
        </div>
        <div className="item author">
          Made with <span>❤</span> by{" "}
          <a href="https://mariusmircioaga.com">Marius Mircioaga</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
